import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import DashboardAvatarMenu from "../../components/DashboardAvatarMenu/DashboardAvatarMenu";
import GeneralConst from "../../consts/GeneralConst";
import GeneralStrings from "../../strings/GeneralStrings.json";
import classNames from "../../utilities/classNames";
import DashboardMobileDrawer from "./DashboardMobileDrawer";
import "./DashboardNavbar.scss";
import {
  applicationItems,
  dashboardItem,
  utilitiesItems,
} from "./dashboard-sidenav-items";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const DashboardNavbar = () => {
  const [open, setOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <AppBar
        className="dashboard-navbar"
        position="fixed"
        elevation={0}
        open={open}
      >
        <Toolbar
          className="navbar-upper"
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              flexGrow: 2,
            }}
          >
            <Typography component="h1" variant="h6" color="inherit" noWrap>
              {GeneralConst.SITE_NAME}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              className="desktop-side-nav-toggle"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginLeft: "50px",
              }}
            >
              <MenuIcon />
            </IconButton>
            <div className="mobile-drawer-toggle">
              <IconButton
                size="medium"
                className="nav-drawer-toggle-button"
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}
              >
                <MenuIcon />
              </IconButton>
              <DashboardMobileDrawer
                isDrawerOpen={isDrawerOpen}
                setIsDrawerOpen={setIsDrawerOpen}
              />
            </div>
          </Box>
          <div className="profile-tools-container margin-right-15">
            <DashboardAvatarMenu />
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className="side-nav"
        position="fixed"
        variant="permanent"
        open={open}
      >
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        ></Toolbar>
        <Divider />
        <List
          {...classNames(open && "open-side-nav-list-container")}
          component="nav"
        >
          <div className="dashboard-item-container">{dashboardItem}</div>
          {open && (
            <>
              <Divider
                className="side-nav-divider"
                variant="middle"
                sx={{ my: 2 }}
              />{" "}
              <ListSubheader
                component="div"
                className="dashboard-nav-sub-header"
              >
                {GeneralStrings.DASHBOARD.APPLICATION}
              </ListSubheader>
            </>
          )}

          {applicationItems}
          {open && (
            <>
              <Divider
                className="side-nav-divider"
                variant="middle"
                sx={{ my: 2 }}
              />{" "}
              <ListSubheader
                component="div"
                className="dashboard-nav-sub-header"
              >
                {GeneralStrings.DASHBOARD.UTILITIES}
              </ListSubheader>
            </>
          )}
          {utilitiesItems}
        </List>
      </Drawer>
    </>
  );
};

export default DashboardNavbar;
