import Box from "@mui/joy/Box";
import React from "react";
import darkRoses from "../../images/backgrounds/dark-rose-buds.jpg";

const SideImage = () => {
  return (
    <Box
      sx={(theme) => ({
        height: "100%",
        position: "fixed",
        right: 0,
        top: 0,
        bottom: 0,
        left: "clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))",
        transition:
          "background-image var(--Transition-duration), left var(--Transition-duration) !important",
        transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
        backgroundColor: "background.level1",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${darkRoses})`,
        [theme.getColorSchemeSelector("dark")]: {
          backgroundImage: `url(${darkRoses})`,
        },
      })}
    />
  );
};

export default SideImage;
