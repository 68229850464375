import PhotoIcon from "@mui/icons-material/Photo";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import AWSService from "../../services/AWSService";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./PhotoGalleryCountCard.scss";

const PhotoGalleryCountCard = () => {
  const [photoCount, setPhotoCount] = useState(0);

  useEffect(() => {
    AWSService.getAllPhotoGalleryImages().then((res) => {
      setPhotoCount(res?.Contents?.length);
    });
  }, []);

  return (
    <Paper elevation={0} className="photo-gallery-count-card">
      <Card>
        <CardContent className="content-container">
          <Grid xs={12} className="icon-container">
            <PhotoIcon sx={{ color: "white", fontSize: "75px" }} />
          </Grid>
          <Grid xs={12} className="data-container">
            <h2>{photoCount}</h2>
            <p className="card-description">
              {GeneralStrings.DASHBOARD.TOTAL_PHOTO_COUNT}
            </p>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default PhotoGalleryCountCard;
