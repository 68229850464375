import Avatar from "@mui/material/Avatar";

import "./DashboardMobileDrawerAccountAvatar.scss";

const DashboardMobileDrawerAccountAvatar = ({ name, position, profilePic }) => {
  return (
    <div className="dashboard-mobile-drawer-account-avatar">
      <div className="user-info-container">
        <span>{name}</span>
        <span className="position">{position}</span>
      </div>
      <div className="avatar-container">
        <Avatar className="avatar" src={profilePic} />
      </div>
    </div>
  );
};

export default DashboardMobileDrawerAccountAvatar;
