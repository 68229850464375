import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardCover from "@mui/joy/CardCover";
import CardOverflow from "@mui/joy/CardOverflow";
import Typography from "@mui/joy/Typography";
import { Alert, Grid, Paper, Snackbar } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal/ConfirmDeleteModal";
import DashboardSubPageToolbar from "../../../components/DashboardSubPageToolbar/DashboardSubPageToolbar";
import GeneralConst from "../../../consts/GeneralConst";
import AWSService from "../../../services/AWSService";
import AlertStrings from "../../../strings/AlertStrings.json";
import GeneralStrings from "../../../strings/GeneralStrings.json";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import AddPhotoModal from "./AddPhotoModal";
import "./PhotoGallery.scss";

const PhotoGallery = () => {
  const [photos, setPhotos] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [deleteValue, setDeleteValue] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showAddPhotoModal, setShowAddPhotoModal] = useState(false);
  const [showDeletePhotoModal, setShowDeletePhotoModal] = useState(false);
  const photoGalleryStrings = GeneralStrings.DASHBOARD.PHOTO_GALLERY;

  const toggleAddPhotoModal = () => setShowAddPhotoModal(!showAddPhotoModal);
  const toggleDeletePhotoModal = () =>
    setShowDeletePhotoModal(!showDeletePhotoModal);

  const addPhoto = (event) => {
    event.preventDefault();
    toggleAddPhotoModal();
  };

  const deletePhoto = (event) => {
    setDeleteValue(event.target.getAttribute("data-value") || null);
    toggleDeletePhotoModal();
  };

  const handleDelete = (formData) => {
    AWSService.deletePhotoGalleryPic(formData.deleteValue)
      .then(() => {
        setSuccessMessage(AlertStrings.DELETE_PHOTO_SUCCESS);
      })
      .catch(() => {
        setFailedMessage(AlertStrings.DELETE_PHOTO_ERROR);
      })
      .finally(() => {
        setShowDeletePhotoModal(false);
        setShowToast(true);
      });
  };

  useEffect(() => {
    AWSService.getAllPhotoGalleryImages().then((res) => {
      setPhotos(res?.Contents?.slice(1));
    });
  }, [showToast]);

  return (
    <DashboardLayout>
      <Grid container spacing={3} className="photo-gallery">
        <DashboardSubPageToolbar
          addButtonText={photoGalleryStrings.ADD_PHOTO}
          addFunction={addPhoto}
          title={photoGalleryStrings.PHOTO_GALLERY}
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showToast}
          autoHideDuration={5000}
          onClose={() => setShowToast(false)}
        >
          <div className="dashboard-snackbar">
            {successMessage && (
              <Alert severity="success">{successMessage}</Alert>
            )}
            {failedMessage && <Alert severity="error">{failedMessage}</Alert>}
          </div>
        </Snackbar>
        <Grid item xs={12}>
          <Paper className="dashboard-table-container row" elevation={0}>
            {!_.isEmpty(photos) &&
              photos.map((photo, index) => {
                const photoKey = photo.Key;
                const photoName = photoKey.split("/")[1];
                const photoUrl =
                  GeneralConst.BUCKET_URL + encodeURIComponent(photoKey);

                return (
                  <div className="col-12 col-md-4 col-lg-3" key={index}>
                    <Card className="photo-gallery-item">
                      <CardCover>
                        <img
                          src={photoUrl}
                          srcSet={photoUrl}
                          loading="lazy"
                          alt={photoKey}
                        />
                      </CardCover>
                      <CardContent sx={{ justifyContent: "flex-end" }}>
                        <Typography className="item-name">
                          {photoName}
                        </Typography>
                      </CardContent>
                      <CardOverflow>
                        <Button
                          type="submit"
                          color="danger"
                          className="text-lighter"
                          data-value={photoKey}
                          onClick={deletePhoto}
                        >
                          {GeneralStrings.FORM.DELETE}
                        </Button>
                      </CardOverflow>
                    </Card>
                  </div>
                );
              })}
          </Paper>
        </Grid>
        <AddPhotoModal
          isOpen={showAddPhotoModal}
          setFailedMessage={setFailedMessage}
          setShowToast={setShowToast}
          setSuccessMessage={setSuccessMessage}
          toggle={toggleAddPhotoModal}
        />
        <ConfirmDeleteModal
          deleteMessage={photoGalleryStrings.DELETE_DESCRIPTION}
          handleDelete={handleDelete}
          isOpen={showDeletePhotoModal}
          deleteValue={deleteValue}
          toggle={toggleDeletePhotoModal}
        />
      </Grid>
    </DashboardLayout>
  );
};

export default PhotoGallery;
