import { Table } from "@mui/joy";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import _ from "lodash";
import { useEffect, useState } from "react";
import UserService from "../../services/UserService";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./UsersDashboardCard.scss";

const UsersDashboardCard = () => {
  const [users, setUsers] = useState([]);
  const usersStrings = GeneralStrings.DASHBOARD.USERS;

  useEffect(() => {
    UserService.getAllUsers().then((response) => {
      const users = response?.data;
      setUsers(users);
    });
  }, []);

  return (
    <Paper elevation={0} className="users-dashboard-card">
      <Card>
        <CardContent className="content-container">
          <h3 className="card-header">{usersStrings.USERS}</h3>
          <div className="card-table-container">
            <Table
              borderAxis="bothBetween"
              className="dashboard-table"
              color="primary"
              size="lg"
              hoverRow
              stripe="odd"
              stickyHeader
              variant="plain"
            >
              <thead>
                <tr>
                  <th style={{ width: 175 }}>{usersStrings.EMAIL}</th>
                  <th>{usersStrings.FIRST_NAME}</th>
                  <th>{usersStrings.LAST_NAME}</th>
                </tr>
              </thead>
              <tbody>
                {!_.isEmpty(users) &&
                  users.map((user) => (
                    <tr key={"user-" + user.email}>
                      <td className="cell-container">{user.email}</td>
                      <td className="cell-container">{user.firstName}</td>
                      <td className="cell-container">{user.lastName}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default UsersDashboardCard;
