/* eslint-disable react-hooks/exhaustive-deps */
import { Table } from "@mui/joy";
import { Alert, Button, Grid, Paper, Snackbar } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import ConfirmDeleteModal from "../../../components/ConfirmDeleteModal/ConfirmDeleteModal";
import DashboardSubPageToolbar from "../../../components/DashboardSubPageToolbar/DashboardSubPageToolbar";
import GeneralConst from "../../../consts/GeneralConst";
import AWSService from "../../../services/AWSService";
import EventService from "../../../services/EventService";
import AlertStrings from "../../../strings/AlertStrings.json";
import GeneralStrings from "../../../strings/GeneralStrings.json";
import { convertISOToLongDate } from "../../../utilities/DateHelper";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import AddEventModal from "./AddEventModal";
import "./Events.scss";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [deleteValue, setDeleteValue] = useState(null);
  const [deleteImgLoc, setDeleteImgLoc] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const [showDeleteEventModal, setShowDeleteEventModal] = useState(false);
  const STATUS_CODES = GeneralConst.STATUS_CODES;
  const eventsStrings = GeneralStrings.DASHBOARD.EVENTS;

  const toggleAddEventModal = () => setShowAddEventModal(!showAddEventModal);
  const toggleDeleteEventModal = () =>
    setShowDeleteEventModal(!showDeleteEventModal);

  const deleteEvent = (event) => {
    setDeleteValue(event.target.getAttribute("data-value") || null);
    setDeleteImgLoc(event.target.getAttribute("data-imgloc") || null);
    toggleDeleteEventModal();
  };

  const addEvent = (event) => {
    event.preventDefault();
    toggleAddEventModal();
  };

  // TODO: Figure out while delete function is not deleting the image
  const handleDelete = (formData) => {
    const { deleteValue, secondaryDeleteValue } = formData;

    if (secondaryDeleteValue !== GeneralConst.EVENT_DEFAULT_IMAGE) {
      AWSService.deleteEventPic(secondaryDeleteValue)
        .then(() => {
          EventService.deleteEvent(deleteValue)
            .then((response) => {
              if (response.data.status === STATUS_CODES.ERROR) {
                setFailedMessage(response?.data?.statusMessage);
              } else {
                setSuccessMessage(AlertStrings.DELETE_EVENT_SUCCESS);
              }
            })
            .catch(() => {
              setFailedMessage(AlertStrings.DELETE_EVENT_ERROR);
            })
            .finally(() => {
              setShowToast(true);
              toggleDeleteEventModal();
            });
        })
        .catch(() => {
          setFailedMessage(AlertStrings.DELETE_IMAGE_ERROR);
          setShowToast(true);
          toggleDeleteEventModal();
        });
    } else {
      EventService.deleteEvent(deleteValue)
        .then((response) => {
          if (response.data.status === STATUS_CODES.ERROR) {
            setFailedMessage(response?.data?.statusMessage);
          } else {
            setSuccessMessage(AlertStrings.DELETE_EVENT_SUCCESS);
          }
        })
        .catch(() => {
          setFailedMessage(AlertStrings.DELETE_EVENT_ERROR);
        })
        .finally(() => {
          setShowToast(true);
          toggleDeleteEventModal();
        });
    }
  };

  useEffect(() => {
    EventService.getAllEvents()
      .then((response) => {
        const events = response?.data;
        setEvents(events);
      })
      .catch((response) => {
        console.log(response);
      });
  }, [showToast]);

  return (
    <DashboardLayout>
      <Grid container spacing={3} className="events-page">
        <DashboardSubPageToolbar
          addButtonText={eventsStrings.ADD_EVENT}
          addFunction={addEvent}
          title={eventsStrings.EVENTS}
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showToast}
          autoHideDuration={5000}
          onClose={() => setShowToast(false)}
        >
          <div className="dashboard-snackbar">
            {successMessage && (
              <Alert severity="success">{successMessage}</Alert>
            )}
            {failedMessage && <Alert severity="error">{failedMessage}</Alert>}
          </div>
        </Snackbar>
        <Grid item xs={12}>
          <Paper className="dashboard-table-container" elevation={0}>
            <Table
              borderAxis="bothBetween"
              className="dashboard-table"
              color="primary"
              size="lg"
              stickyHeader
              hoverRow
              stripe="odd"
              variant="plain"
            >
              <thead>
                <tr>
                  <th style={{ width: "50%" }}>{eventsStrings.TITLE}</th>
                  <th style={{ width: "40%" }}>{eventsStrings.DATE}</th>
                  <th style={{ width: "10%" }}></th>
                </tr>
              </thead>
              <tbody>
                {!_.isEmpty(events) &&
                  events.map((event) => (
                    <tr key={"event-" + event.title}>
                      <td className="ellipsis nowrap">{event.title}</td>
                      <td className="ellipsis nowrap">
                        {convertISOToLongDate(event.date)}
                      </td>
                      <td className="flex flex-row jc-center ellipsis nowrap">
                        <Button
                          className="dashboard-button"
                          color="error"
                          variant="contained"
                          data-value={event.id}
                          data-imgloc={event.imgLoc}
                          onClick={deleteEvent}
                        >
                          {GeneralStrings.FORM.DELETE}
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Paper>
        </Grid>
        <AddEventModal
          isOpen={showAddEventModal}
          setFailedMessage={setFailedMessage}
          setShowToast={setShowToast}
          setSuccessMessage={setSuccessMessage}
          toggle={toggleAddEventModal}
        />
        <ConfirmDeleteModal
          deleteMessage={eventsStrings.DELETE_DESCRIPTION}
          handleDelete={handleDelete}
          isOpen={showDeleteEventModal}
          deleteValue={deleteValue}
          secondaryDeleteValue={deleteImgLoc}
          toggle={toggleDeleteEventModal}
        />
      </Grid>
    </DashboardLayout>
  );
};

export default Events;
