import EmailIcon from "@mui/icons-material/Email";
import LogoutIcon from "@mui/icons-material/Logout";
import { SwipeableDrawer } from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Toolbar from "@mui/material/Toolbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DashboardMobileDrawerAccountAvatar from "../../components/DashboardMobileDrawerAccountAvatar/DashboardMobileDrawerAccountAvatar.jsx";
import GeneralConst from "../../consts/GeneralConst.js";
import RoutesConst from "../../consts/RoutesConst.js";
import UserService from "../../services/UserService.js";
import { logout, selectUser } from "../../slices/userSlice";
import GeneralStrings from "../../strings/GeneralStrings";
import "./DashboardMobileDrawer.scss";
import {
  applicationItems,
  dashboardItem,
  utilitiesItems,
} from "./dashboard-sidenav-items";

const DashboardMobileDrawer = ({ isDrawerOpen, setIsDrawerOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const { email } = user;
  const [userInfo, setUserInfo] = useState({});

  const handleLogout = () => {
    dispatch(logout());
    navigate(RoutesConst.DEFAULT);
  };

  useEffect(() => {
    UserService.getUserByEmail(email).then((res) => {
      setUserInfo(res.data);
    });
  }, [email]);

  return (
    <SwipeableDrawer
      anchor="left"
      className="dashboard-mobile-drawer"
      open={isDrawerOpen}
      onOpen={() => {}}
      onClose={() => setIsDrawerOpen(false)}
    >
      <Toolbar
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          px: [1],
        }}
      ></Toolbar>
      <Divider />
      <List component="nav">
        <DashboardMobileDrawerAccountAvatar
          name={`${userInfo?.firstName} ${userInfo?.lastName}`}
          position={userInfo?.position}
          profilePic={userInfo?.profilePic}
        />
        <ListItem
          component="a"
          href={GeneralConst.MAIL_SERVER}
          target="_blank"
          className="dashboard-nav-list-item"
        >
          <ListItemIcon className="dashboard-nav-icon">
            <EmailIcon />
          </ListItemIcon>
          <ListItemText primary={GeneralStrings.DASHBOARD.NAV.EMAIL} />
        </ListItem>
        <ListItem
          component="a"
          onClick={handleLogout}
          className="dashboard-nav-list-item"
        >
          <ListItemIcon className="dashboard-nav-icon">
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={GeneralStrings.DASHBOARD.NAV.LOGOUT} />
        </ListItem>
        <Divider className="divider" variant="middle" sx={{ my: 2 }} />
        <ListSubheader component="div" className="dashboard-nav-sub-header">
          {GeneralStrings.DASHBOARD.APPLICATION}
        </ListSubheader>
        {dashboardItem}
        {applicationItems}
        <Divider className="divider" variant="middle" sx={{ my: 2 }} />
        <ListSubheader component="div" className="dashboard-nav-sub-header">
          {GeneralStrings.DASHBOARD.UTILITIES}
        </ListSubheader>
        {utilitiesItems}
      </List>
    </SwipeableDrawer>
  );
};

export default DashboardMobileDrawer;
