import Grid from "@mui/material/Grid";
import EventCountCard from "../../components/EventCountCard/EventCountCard";
import EventDashboardCard from "../../components/EventDashboardCard/EventDashboardCard";
import PhotoGalleryCountCard from "../../components/PhotoGalleryCountCard/PhotoGalleryCountCard";
import SiteViewCountCard from "../../components/SiteViewCountCard/SiteViewCountCard";
import UserCountCard from "../../components/UserCountCard/UserCountCard";
import UsersDashboardCard from "../../components/UsersDashboardCard/UsersDashboardCard";
import "./Dashboard.scss";
import DashboardLayout from "./DashboardLayout/DashboardLayout";

const Dashboard = () => {
  return (
    <DashboardLayout>
      <Grid container spacing={3}>
        <Grid item xs={12} md={3}>
          <SiteViewCountCard />
        </Grid>
        <Grid item xs={12} md={3}>
          <EventCountCard />
        </Grid>
        <Grid item xs={12} md={3}>
          <PhotoGalleryCountCard />
        </Grid>
        <Grid item xs={12} md={3}>
          <UserCountCard />
        </Grid>
        <Grid item xs={12} md={8}>
          <EventDashboardCard />
        </Grid>
        <Grid item xs={12} md={4}>
          <UsersDashboardCard />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default Dashboard;
