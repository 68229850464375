import Skeleton from "@mui/material/Skeleton";
import "./AccountSettingsSkeleton.scss";

const AccountSettingsSkeleton = () => {
  return (
    <div className="account-settings-skeleton">
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={40}
        sx={{ marginTop: "10px", marginBottom: "25px" }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={40}
        sx={{ marginBottom: "25px" }}
      />
      <Skeleton
        variant="rectangular"
        animation="wave"
        height={40}
        sx={{ marginBottom: "25px" }}
      />
    </div>
  );
};

export default AccountSettingsSkeleton;
