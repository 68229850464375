import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import AWSService from "../../../services/AWSService.js";
import AlertStrings from "../../../strings/AlertStrings.json";
import GeneralStrings from "../../../strings/GeneralStrings.json";
import "./AddPhotoModal.scss";

const AddPhotoModal = ({
  isOpen,
  setFailedMessage,
  setShowToast,
  setSuccessMessage,
  toggle,
}) => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const photoGalleryStrings = GeneralStrings.DASHBOARD.PHOTO_GALLERY;

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const handleSubmit = () => {
    setIsLoading(true);

    if (file) {
      AWSService.uploadPhotoGalleryPic(file)
        .then((res) => {
          setSuccessMessage(AlertStrings.ADD_PHOTO_SUCCESS);
          setShowToast(true);
          setIsLoading(false);
          toggle();
        })
        .catch(() => {
          setFailedMessage(AlertStrings.UPLOAD_IMAGE_FAIL);
          setIsLoading(false);
          toggle();
        });
    } else {
      setFailedMessage(AlertStrings.ADD_PHOTO_ERROR);
      setShowToast(true);
      setIsLoading(false);
      toggle();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      toggle={toggle}
      className="add-photo-modal"
    >
      <ModalHeader toggle={toggle}>{photoGalleryStrings.ADD_PHOTO}</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            title: null,
            date: null,
            eventLink: null,
          }}
          onSubmit={handleSubmit}
        >
          <Form className="padding-left-50 padding-right-50 margin-bottom-25">
            <FormControl className="margin-bottom-35">
              <FormLabel>{GeneralStrings.FORM.IMAGE}</FormLabel>
              <Field
                name="image"
                accept="image/*"
                type="file"
                onChange={handleFileChange}
                className="form-control"
              />
              <ErrorMessage
                component="span"
                className="form-error"
                name="image"
              />
            </FormControl>
            <div className="flex flex-row">
              <Button
                type="submit"
                color="success"
                disabled={isLoading}
                className="text-lighter margin-left-auto"
              >
                {GeneralStrings.FORM.SUBMIT}
                {isLoading && (
                  <Spinner color="dark" size="sm" className="margin-left-10" />
                )}
              </Button>
            </div>
          </Form>
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default AddPhotoModal;
