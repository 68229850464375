/* eslint-disable import/no-anonymous-default-export */
export default {
  BUCKET_URL: "https://floraldesignlab.s3.us-east-2.amazonaws.com/",
  DOMAIN_NAME: "floraldesignlab.com",
  EVENT_CARD_SIZE: {
    NORMAL: "nm",
    SMALL: "sm",
  },
  EVENT_DEFAULT_IMAGE:
    "https://floraldesignlab.s3.us-east-2.amazonaws.com/events/default.png",
  LOGIN_FORM_TYPE: {
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    LOGIN: "LOGIN",
    RESET_PASSWORD: "RESET_PASSWORD",
  },
  MAIL_SERVER: "http://mail.floraldesignlab.com/Login.aspx",
  SITE_NAME: "Floral Design Lab",
  STATUS_CODES: {
    DATA_ERROR: "DATA_ERROR",
    ERROR: "ERROR",
    PARTIAL_SUCCESS: "PARTIAL_SUCCESS",
    PENDING: "PENDING",
    SUCCESS: "SUCCESS",
  },
};
