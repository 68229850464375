import GlobalStyles from "@mui/joy/GlobalStyles";
import { useState } from "react";
import { Helmet } from "react-helmet";
import GeneralConst from "../../consts/GeneralConst.js";
import ForgotPasswordForm from "./ForgotPasswordForm.jsx";
import "./LoginPage.scss";
import ResetPasswordForm from "./ResetPasswordForm.jsx";
import SideImage from "./SideImage.jsx";
import SignInForm from "./SignInForm.jsx";

const TITLE = `Login | ${GeneralConst.SITE_NAME}`;

const LoginPage = () => {
  const [formType, setFormType] = useState(GeneralConst.LOGIN_FORM_TYPE.LOGIN);
  const [emailInput, setEmail] = useState("");

  return (
    <div className="login-page">
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <GlobalStyles
        styles={{
          ":root": {
            "--Collapsed-breakpoint": "769px", // form will stretch when viewport is below `769px`
            "--Cover-width": "50vw", // must be `vw` only
            "--Form-maxWidth": "800px",
            "--Transition-duration": "0.4s", // set to `none` to disable transition
            "--Input-focusedHighlight": "#c5a47e",
          },
        }}
      />

      {formType === GeneralConst.LOGIN_FORM_TYPE.FORGOT_PASSWORD ? (
        <ForgotPasswordForm setFormType={setFormType} />
      ) : formType === GeneralConst.LOGIN_FORM_TYPE.RESET_PASSWORD ? (
        <ResetPasswordForm email={emailInput} />
      ) : (
        <SignInForm setEmail={setEmail} setFormType={setFormType} />
      )}
      <SideImage />
    </div>
  );
};

export default LoginPage;
