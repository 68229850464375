import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import AccountPositions from "../../../consts/AccountPositionsConst.js";
import UserService from "../../../services/UserService";
import AlertStrings from "../../../strings/AlertStrings.json";
import GeneralStrings from "../../../strings/GeneralStrings.json";
import {
  validateConfirmPassword,
  validateEmail,
  validatePassword,
  validateTextField,
} from "../../../utilities/ValidationHelper";
import "./AddUserModal.scss";

const AddUserModal = ({
  isOpen,
  setFailedMessage,
  setShowToast,
  setSuccessMessage,
  toggle,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const userStrings = GeneralStrings.DASHBOARD.USERS;

  const handleSubmit = (formValues) => {
    setIsLoading(true);

    UserService.addUser(formValues)
      .then(() => {
        setSuccessMessage(AlertStrings.ADD_USER_SUCCESS);
      })
      .catch(() => {
        setFailedMessage(AlertStrings.ADD_USER_ERROR);
      })
      .finally(() => {
        setShowToast(true);
        setIsLoading(false);
        toggle();
      });
  };

  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle} className="add-user-modal">
      <ModalHeader toggle={toggle}>{userStrings.ADD_USER}</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            email: null,
            firstName: null,
            lastName: null,
            position: null,
            password: null,
            confirmPassword: null,
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          {({ values }) => (
            <Form className="padding-left-50 padding-right-50 margin-bottom-25">
              <FormControl className="margin-bottom-35" required>
                <FormLabel>{GeneralStrings.FORM.EMAIL}</FormLabel>
                <Field
                  name="email"
                  className="form-control"
                  validate={validateEmail}
                />
                <ErrorMessage
                  component="span"
                  className="form-error"
                  name="email"
                />
              </FormControl>
              <FormControl className="margin-bottom-35" required>
                <FormLabel>{GeneralStrings.FORM.FIRST_NAME}</FormLabel>
                <Field
                  name="firstName"
                  className="form-control"
                  validate={validateTextField}
                />
                <ErrorMessage
                  component="span"
                  className="form-error"
                  name="firstName"
                />
              </FormControl>
              <FormControl className="margin-bottom-35" required>
                <FormLabel>{GeneralStrings.FORM.LAST_NAME}</FormLabel>
                <Field
                  name="lastName"
                  className="form-control"
                  validate={validateTextField}
                />
                <ErrorMessage
                  component="span"
                  className="form-error"
                  name="lastName"
                />
              </FormControl>
              <FormControl className="margin-bottom-35">
                <FormLabel>{GeneralStrings.FORM.POSITION}</FormLabel>
                <Field
                  as="select"
                  className="form-control select-control"
                  name="position"
                >
                  <option></option>
                  {AccountPositions.map((position) => (
                    <option key={position} value={position}>
                      {position}
                    </option>
                  ))}
                </Field>
              </FormControl>
              <FormControl className="margin-bottom-35" required>
                <FormLabel>{GeneralStrings.FORM.PASSWORD}</FormLabel>
                <Field
                  name="password"
                  type="password"
                  className="form-control"
                  validate={validatePassword}
                />
                <ErrorMessage
                  component="span"
                  className="form-error"
                  name="password"
                />
              </FormControl>
              <FormControl className="margin-bottom-35" required>
                <FormLabel>{GeneralStrings.FORM.CONFIRM_PASSWORD}</FormLabel>
                <Field
                  name="confirmPassword"
                  type="password"
                  className="form-control"
                  validate={(value) =>
                    validateConfirmPassword(values.password, value)
                  }
                />
                <ErrorMessage
                  component="span"
                  className="form-error"
                  name="confirmPassword"
                />
              </FormControl>
              <div className="flex flex-row">
                <Button
                  type="submit"
                  color="success"
                  disabled={isLoading}
                  className="text-lighter margin-left-auto"
                >
                  {GeneralStrings.FORM.SUBMIT}
                  {isLoading && (
                    <Spinner
                      color="dark"
                      size="sm"
                      className="margin-left-10"
                    />
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default AddUserModal;
