import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import UserService from "../../services/UserService";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./UserCountCard.scss";

const UserCountCard = () => {
  const [viewCount, setViewCount] = useState(0);

  useEffect(() => {
    UserService.getTotalUserCount().then((res) => {
      setViewCount(res.data);
    });
  }, []);

  return (
    <Paper elevation={0} className="user-count-card">
      <Card>
        <CardContent className="content-container">
          <Grid xs={12} className="icon-container">
            <PeopleAltIcon sx={{ color: "white", fontSize: "75px" }} />
          </Grid>
          <Grid xs={12} className="data-container">
            <h2>{viewCount}</h2>
            <p className="card-description">
              {GeneralStrings.DASHBOARD.TOTAL_USER_COUNT}
            </p>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default UserCountCard;
