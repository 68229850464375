/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import EndpointsConst from "../consts/EndpointsConst";
import { getAPIUrl } from "../utilities/APIHelper";

class EventService {
  /**
   * [POST]
   * Submits the new event to the database
   * @param {String} title
   * @param {String} date
   * @param {String} eventLink
   * @param {String} imgLoc
   * @returns {Promise}
   */
  addEvent({ title, date, eventLink, imgLoc }) {
    return axios({
      method: "POST",
      url: getAPIUrl() + EndpointsConst.API.EVENTS.ADD_EVENT,
      headers: {},
      data: { title, date, link: eventLink, imgLoc },
    });
  }

  /**
   * [DELETE]
   * Deletes event from database
   * @param {Number} id
   * @returns {Promise}
   */
  deleteEvent(id) {
    return axios({
      method: "DELETE",
      url:
        getAPIUrl() +
        EndpointsConst.API.EVENTS.DELETE_EVENT.replace("{id}", id),
      headers: {},
    });
  }

  /**
   * [GET]
   * Gets all events
   * @returns {Promise}
   */
  getAllEvents() {
    /* return axios({
      method: "GET",
      url: getAPIUrl() + EndpointsConst.API.EVENTS.GET_ALL_EVENTS,
      headers: {},
    }); */
    let url = getAPIUrl() + EndpointsConst.API.EVENTS.GET_ALL_EVENTS;
    return axios.get(url);
  }

  /**
   * [GET]
   * Retrieves total event count
   * @returns {Promise}
   */
  getTotalEventCount() {
    return axios({
      method: "GET",
      url: getAPIUrl() + EndpointsConst.API.EVENTS.GET_TOTAL_EVENT_COUNT,
      headers: {},
    });
  }
}

export default new EventService();
