/**
 * Sanitizes, concatenates and returns an Object container the className property
 *
 * @param {...String|boolean|undefined} args Many class names
 * @returns {Object}
 */

export default function classNames(...args) {
  if (!args || !args.length) {
    return {};
  }

  const className = Array.prototype.filter
    .call(args, (className) => !!className)
    .reduce((arr1, arr2) => arr1.concat(arr2), [])
    .join(" ");

  return className ? { className } : {};
}
