import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import AboutUs from "../components/AboutUs/AboutUs";
import Footer from "../components/Footer/Footer";
import HomepageHero from "../components/HomepageHero/HomepageHero";
import HomepageNavbar from "../components/HomepageNavbar/HomepageNavbar";
import GeneralConst from "../consts/GeneralConst";
import AVAService from "../services/AVAService";

const TITLE = `Home | ${GeneralConst.SITE_NAME}`;

const Homepage = () => {
  useEffect(() => {
    AVAService.addSiteView(GeneralConst.DOMAIN_NAME);
  }, []);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <HomepageNavbar />
      <HomepageHero />
      <AboutUs />
      <Footer />
    </>
  );
};

export default Homepage;
