/* eslint-disable import/no-anonymous-default-export */
var AWS = require("aws-sdk");
const S3_BUCKET = "floraldesignlab";
const REGION = "us-east-2";

class AWSService {
  /**
   * [DELETE]
   * Deletes file in S3 bucket
   * @param {String} fileName
   * @returns {Promise}
   */
  deleteEventPic(fileName) {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_KEY,
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: "events/" + fileName,
    };

    var deletionRequest = s3.deleteObject(params).promise();

    return deletionRequest;
  }

  /**
   * [DELETE]
   * Deletes file in S3 bucket
   * @param {String} fileLoc
   * @returns {Promise}
   */
  deletePhotoGalleryPic(fileLoc) {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_KEY,
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: fileLoc,
    };

    var deletionRequest = s3.deleteObject(params).promise();

    return deletionRequest;
  }

  /**
   * [DELETE]
   * Deletes file in S3 bucket
   * @param {String} fileName
   * @returns {Promise}
   */
  deleteProfilePic(fileName) {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_KEY,
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: "profile-pictures/" + fileName,
    };

    var deletionRequest = s3.deleteObject(params).promise();

    return deletionRequest;
  }

  /**
   * [GET]
   * Retrieves all photo gallery images in S3 bucket
   * @returns {Promise}
   */
  getAllPhotoGalleryImages() {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_KEY,
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    var upload = s3.listObjects({ Prefix: "photo-gallery" }).promise();

    return upload;
  }

  /**
   * [PUT]
   * Uploads event picture into S3 bucket
   * @param {File} profilePic
   * @param {String} profilePicName
   * @returns {Promise}
   */
  uploadEventPic(eventPic, eventPicName) {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_KEY,
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const extention = `.${eventPic.type.split("/").pop()}`;

    const params = {
      Bucket: S3_BUCKET,
      Key: `events/${eventPicName.replace(/ /g, "_")}${extention}`,
      Body: eventPic,
    };

    var upload = s3.upload(params).promise();

    return upload;
  }

  /**
   * [PUT]
   * Uploads photo gallery picture into S3 bucket
   * @param {File} photo
   * @returns {Promise}
   */
  uploadPhotoGalleryPic(photo) {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_KEY,
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: `photo-gallery/${photo.name.replace(/ /g, "_")}`,
      Body: photo,
    };

    var upload = s3.upload(params).promise();

    return upload;
  }

  /**
   * [PUT]
   * Uploads profile picture into S3 bucket
   * @param {File} profilePic
   * @param {String} profilePicName
   * @returns {Promise}
   */
  uploadProfilePic(profilePic, profilePicName) {
    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_KEY,
    });

    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const extention = `.${profilePic.type.split("/").pop()}`;

    const params = {
      Bucket: S3_BUCKET,
      Key: `profile-pictures/${profilePicName}${extention}`,
      Body: profilePic,
    };

    var upload = s3.upload(params).promise();

    return upload;
  }
}

export default new AWSService();
