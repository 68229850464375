import { Alert, Snackbar } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import EventCard from "../../components/EventCard/EventCard";
import Footer from "../../components/Footer/Footer";
import HomepageNavbar from "../../components/HomepageNavbar/HomepageNavbar";
import SubscriptionItem from "../../components/SubscriptionItem/SubscriptionItem";
import GeneralConst from "../../consts/GeneralConst";
import EventService from "../../services/EventService";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./EventsPage.scss";

const TITLE = `Events | ${GeneralConst.SITE_NAME}`;

const EventsPage = () => {
  const [events, setEvents] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [failedMessage, setFailedMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const eventsStrings = GeneralStrings.EVENTS;

  useEffect(() => {
    EventService.getAllEvents().then((response) => {
      const events = response?.data;
      setEvents(events);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <HomepageNavbar />
      <div className="events">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showToast}
          autoHideDuration={5000}
          onClose={() => setShowToast(false)}
        >
          <div className="paypal-snackbar">
            {successMessage && (
              <Alert severity="success">{successMessage}</Alert>
            )}
            {failedMessage && <Alert severity="error">{failedMessage}</Alert>}
          </div>
        </Snackbar>
        <div className="container section-container">
          <div className="row padding-top-50 padding-bottom-50">
            <h2 className="header cursive text-center">
              {eventsStrings.UPCOMING_EVENTS}
            </h2>
            <div className="events-container container">
              <div className="row">
                {!_.isEmpty(events) &&
                  events.map((event) => (
                    <div
                      className="col-sm-12 col-md-6 col-lg-4 col-xl-3 margin-bottom-50"
                      key={"event-" + event.id}
                    >
                      <EventCard event={event} />
                    </div>
                  ))}
              </div>
            </div>
            <hr />
            <h2 className="header cursive text-center margin-top-50">
              {eventsStrings.SUBSCRIPTIONS}
            </h2>
            <div className="col-md-12 col-lg-6">
              <SubscriptionItem
                content={eventsStrings.ONE_PERSON_SUB}
                paypalPlanId={process.env.REACT_APP_PAYPAL_PLAN_ID_ONE_PERSON}
                setFailedMessage={setFailedMessage}
                setShowToast={setShowToast}
                setSuccessMessage={setSuccessMessage}
                variant="blue"
              />
            </div>
            <div className="col-md-12 col-lg-6">
              <SubscriptionItem
                content={eventsStrings.TWO_PERSON_SUB}
                paypalPlanId={process.env.REACT_APP_PAYPAL_PLAN_ID_TWO_PERSON}
                setFailedMessage={setFailedMessage}
                setShowToast={setShowToast}
                setSuccessMessage={setSuccessMessage}
                variant="pink"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EventsPage;
