import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import EventService from "../../services/EventService.js";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./EventCountCard.scss";

const EventCountCard = () => {
  const [eventCount, setEventCount] = useState(0);

  useEffect(() => {
    EventService.getTotalEventCount().then((res) => {
      setEventCount(res.data);
    });
  }, []);

  return (
    <Paper elevation={0} className="event-count-card">
      <Card>
        <CardContent className="content-container">
          <Grid xs={12} className="icon-container">
            <CalendarMonthIcon sx={{ color: "white", fontSize: "75px" }} />
          </Grid>
          <Grid xs={12} className="data-container">
            <h2>{eventCount}</h2>
            <p className="card-description">
              {GeneralStrings.DASHBOARD.ACTIVE_EVENTS_COUNT}
            </p>
          </Grid>
        </CardContent>
      </Card>
    </Paper>
  );
};

export default EventCountCard;
