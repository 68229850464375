/* eslint-disable import/no-anonymous-default-export */
export default {
  ABOUT_US: "/about-us",
  CONTACT: "/contact",
  DEFAULT: "/",
  DASHBOARD: {
    ACCOUNT_SETTINGS: "/dashboard/account-settings",
    DEFAULT: "/dashboard",
    EVENTS: "/dashboard/events",
    HELP: "/dashboard/help",
    PHOTO_GALLERY: "/dashboard/photo-gallery",
    USERS: "/dashboard/users",
  },
  EVENTS: "/events",
  LOGIN: "/login",
  PHOTO_GALLERY: "/photo-gallery",
};
