import { Link } from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Helmet } from "react-helmet";
import Footer from "../../components/Footer/Footer.jsx";
import HomepageNavbar from "../../components/HomepageNavbar/HomepageNavbar.jsx";
import GeneralConst from "../../consts/GeneralConst.js";
import AWSService from "../../services/AWSService.js";
import GeneralStrings from "../../strings/GeneralStrings.json";
import { getS3BucketItemURL } from "../../utilities/AWSHelper.js";
import "./PhotoGalleryPage.scss";

const TITLE = `Photo Gallery | ${GeneralConst.SITE_NAME}`;

const PhotoGalleryPage = () => {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    AWSService.getAllPhotoGalleryImages().then((res) => {
      setPhotos(res?.Contents?.slice(1));
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <HomepageNavbar />
      <div className="photo-gallery-page" id="photo-gallery">
        <div className="container section-container">
          <div className="row padding-top-50 padding-bottom-50">
            <h2 className="header cursive text-center">
              {GeneralStrings.PHOTO_GALLERY.TITLE}
            </h2>
            <div className="photo-gallery-container text-center">
              <ImageList variant="masonry" cols={isMobile ? 2 : 3} gap={8}>
                {photos &&
                  photos.map((item, index) => {
                    const itemURL = getS3BucketItemURL(item?.Key);

                    return (
                      <Link href={itemURL} key={`image-${index}`}>
                        <ImageListItem key={item.img}>
                          <img
                            srcSet={itemURL}
                            src={itemURL}
                            alt={item?.Key}
                            loading="lazy"
                          />
                        </ImageListItem>
                      </Link>
                    );
                  })}
              </ImageList>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PhotoGalleryPage;
