import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Paper,
  Typography,
} from "@mui/material";
import _ from "lodash";
import GeneralStrings from "../../strings/GeneralStrings.json";
import classNames from "../../utilities/classNames";
import "./DashboardSubPageToolbar.scss";

const DashboardSubPageToolbar = ({
  addButtonText,
  addFunction,
  progressBarData,
  title = GeneralStrings.DEFAULTS.TOOLBAR_TITLE,
}) => {
  const ToolbarProgressBar = () => {
    const { count, limit, title } = progressBarData;

    const getBarColor = () => {
      if (count >= limit) {
        return "progressbar-full";
      } else if (count >= limit * 0.85) {
        return "progressbar-warning";
      } else {
        return "progressbar-good-status";
      }
    };

    return (
      <div className="progressbar-container" elevation={0}>
        <h6 className="progressbar-title">
          {title || GeneralStrings.DEFAULTS.PROGRESS_BAR}
        </h6>
        <Box
          sx={{ width: "50%" }}
          className="margin-left-15 margin-vertical-center"
        >
          <LinearProgress
            {...classNames("dashboard-linear-progressbar", getBarColor())}
            variant="determinate"
            value={count}
          />
        </Box>
        <Box
          sx={{ minWidth: 35 }}
          className="margin-left-15 margin-vertical-center"
        >
          <Typography variant="body2">
            {count}/{limit}
          </Typography>
        </Box>
      </div>
    );
  };

  return (
    <Grid item xs={12} className="dashboard-sub-page-toolbar">
      <Paper className="toolbar-container" elevation={0}>
        <h4 className="toolbar-title">{title}</h4>
        <div className="margin-left-auto">
          <div className="flex flex-row">
            {!_.isEmpty(progressBarData) && ToolbarProgressBar()}
            {addFunction && (
              <Button
                className="dashboard-button"
                color="success"
                onClick={(event) => addFunction(event)}
                startIcon={<i className="fa-solid fa-plus" />}
                variant="contained"
              >
                {addButtonText || GeneralStrings.DEFAULTS.BUTTON}
              </Button>
            )}
          </div>
        </div>
      </Paper>
    </Grid>
  );
};

export default DashboardSubPageToolbar;
