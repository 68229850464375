export function convertISOToLongDate(isoDate) {
  const date = new Date(isoDate);
  const month = date.toLocaleString("default", { month: "long" });
  const day = `${parseInt(
    isoDate.split("-")[2].toLocaleString("default", {
      day: "numeric",
    }),
    10
  )}${getDateOrdinal(
    isoDate.split("-")[2].toLocaleString("default", { day: "numeric" })
  )}`;
  const year = date.getFullYear();
  return `${month} ${day}, ${year}`;
}

export function getCurrentYear() {
  return new Date().getFullYear();
}

export function getFullCurrentDate() {
  const date = new Date();
  const month = date.toLocaleString("default", { month: "long" });
  const day = `${date.toLocaleString("default", {
    day: "numeric",
  })}${getDateOrdinal(date.toLocaleString("default", { day: "numeric" }))}`;
  const year = date.getFullYear();

  return `${month} ${day}, ${year}`;
}

const getDateOrdinal = (number) => {
  if (number > 3 && number < 21) return "th";
  switch (number % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
