import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupIcon from "@mui/icons-material/Group";
import HelpIcon from "@mui/icons-material/Help";
import PhotoIcon from "@mui/icons-material/Photo";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import RoutesConst from "../../consts/RoutesConst.js";
import GeneralStrings from "../../strings/GeneralStrings.json";

const NAV_STRINGS = GeneralStrings.DASHBOARD.NAV;

export const dashboardItem = (
  <>
    <ListItem
      component="a"
      href={RoutesConst.DASHBOARD.DEFAULT}
      className="dashboard-nav-list-item"
    >
      <ListItemIcon className="dashboard-nav-icon">
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.DASHBOARD} />
    </ListItem>
  </>
);

export const applicationItems = (
  <>
    <ListItem
      component="a"
      href={RoutesConst.DASHBOARD.EVENTS}
      className="dashboard-nav-list-item"
    >
      <ListItemIcon className="dashboard-nav-icon">
        <CalendarMonthIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.EVENTS} />
    </ListItem>
    <ListItem
      component="a"
      href={RoutesConst.DASHBOARD.PHOTO_GALLERY}
      className="dashboard-nav-list-item"
    >
      <ListItemIcon className="dashboard-nav-icon">
        <PhotoIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.PHOTO_GALLERY} />
    </ListItem>
    <ListItem
      component="a"
      href={RoutesConst.DASHBOARD.USERS}
      className="dashboard-nav-list-item"
    >
      <ListItemIcon className="dashboard-nav-icon">
        <GroupIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.USERS} />
    </ListItem>
  </>
);

export const utilitiesItems = (
  <>
    <ListItem
      component="a"
      href={RoutesConst.DASHBOARD.ACCOUNT_SETTINGS}
      className="dashboard-nav-list-item"
    >
      <ListItemIcon className="dashboard-nav-icon">
        <SettingsSuggestIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.ACCOUNT_SETTINGS} />
    </ListItem>
    <ListItem
      component="a"
      href={RoutesConst.DASHBOARD.HELP}
      className="dashboard-nav-list-item"
    >
      <ListItemIcon className="dashboard-nav-icon">
        <HelpIcon />
      </ListItemIcon>
      <ListItemText primary={NAV_STRINGS.HELP} />
    </ListItem>
  </>
);
