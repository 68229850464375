import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import React from "react";
import AlertStrings from "../../strings/AlertStrings.json";
import GeneralStrings from "../../strings/GeneralStrings.json";
import classNames from "../../utilities/classNames";
import "./SubscriptionItem.scss";

const payPalButtonStyles = {
  color: "blue",
  layout: "horizontal",
  shape: "rect",
  tagline: false,
};

const initialOptions = {
  "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID_LIVE,
  vault: "true",
  intent: "subscription",
};

const SubscriptionItem = ({
  content,
  paypalPlanId,
  setFailedMessage,
  setShowToast,
  setSuccessMessage,
  variant,
}) => {
  const getSubscriptionIcon = (subscription) => {
    switch (subscription) {
      case GeneralStrings.EVENTS.ONE_PERSON_SUB.HEADER:
        return <i className="fa-solid fa-user fa-5x" />;
      default:
        return <i className="fa-solid fa-users fa-5x" />;
    }
  };

  return (
    <div {...classNames("subscription-item", variant)}>
      <span className="heading">
        {getSubscriptionIcon(content.HEADER)}
        <span className="subscription-price">{content.PRICE}</span>
        <span className="subscription-title">{content.HEADER}</span>
      </span>
      <p
        className="subscription-item-description"
        dangerouslySetInnerHTML={{
          __html: content?.DESCRIPTION_HTML,
        }}
      />
      <PayPalScriptProvider options={initialOptions}>
        <PayPalButtons
          options={{ vault: true }}
          createSubscription={(data, actions) => {
            return actions.subscription.create({
              plan_id: paypalPlanId,
            });
          }}
          onApprove={(data, actions) => {
            return actions.subscription
              .get()
              .then((details) => {
                setSuccessMessage &&
                  setSuccessMessage(AlertStrings.SUBSCRIPTION_SUCCESS);
              })
              .catch(() => {
                setFailedMessage &&
                  setFailedMessage(AlertStrings.SUBSCRIPTION_ERROR);
              })
              .finally(() => {
                setShowToast && setShowToast(true);
              });
          }}
          style={payPalButtonStyles}
        />
      </PayPalScriptProvider>
    </div>
  );
};

export default SubscriptionItem;
