import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import { Link } from "@mui/material";
import React from "react";
import GeneralConst from "../../consts/GeneralConst";
import GeneralStrings from "../../strings/GeneralStrings.json";
import classNames from "../../utilities/classNames";
import { convertISOToLongDate } from "../../utilities/DateHelper";
import "./EventCard.scss";

const EventCard = ({ event, size = GeneralConst.EVENT_CARD_SIZE.NORMAL }) => {
  return (
    <div className="event-card">
      <Card
        variant="outlined"
        sx={
          size === GeneralConst.EVENT_CARD_SIZE.SMALL
            ? { minHeight: 350, width: 275 }
            : { minHeight: 365, width: 300 }
        }
        className="event-card-container"
      >
        <CardOverflow>
          <AspectRatio ratio="2">
            <img
              src={event?.imgLoc}
              srcSet={event?.imgLoc}
              loading="lazy"
              alt="Event"
            />
          </AspectRatio>
        </CardOverflow>
        <CardContent>
          <span
            {...classNames(
              "event-title",
              size === GeneralConst.EVENT_CARD_SIZE.SMALL && "trunticated-title"
            )}
          >
            {event?.title}
          </span>
          <p className="event-date">{convertISOToLongDate(event?.date)}</p>
        </CardContent>
        <Link href={event?.link || "#"} target="_blank" className="event-link">
          {GeneralStrings.EVENTS.VIEW_EVENT}
        </Link>
      </Card>
    </div>
  );
};

export default EventCard;
