import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import GeneralConst from "../../../consts/GeneralConst";
import AWSService from "../../../services/AWSService.js";
import EventService from "../../../services/EventService";
import AlertStrings from "../../../strings/AlertStrings.json";
import GeneralStrings from "../../../strings/GeneralStrings.json";
import { validateTextField } from "../../../utilities/ValidationHelper";
import "./AddEventModal.scss";

const AddEventModal = ({
  isOpen,
  setFailedMessage,
  setShowToast,
  setSuccessMessage,
  toggle,
}) => {
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const STATUS_CODES = GeneralConst.STATUS_CODES;
  const eventStrings = GeneralStrings.DASHBOARD.EVENTS;

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
  };

  const handleSubmit = (formValues) => {
    setIsLoading(true);
    const { date, title, eventLink } = formValues;

    if (file) {
      AWSService.uploadEventPic(file, title)
        .then((res) => {
          const imgLoc = res.Location;
          EventService.addEvent({ title, eventLink, date, imgLoc })
            .then((response) => {
              if (response.data.status === STATUS_CODES.ERROR) {
                setFailedMessage(response?.data?.statusMessage);
              } else {
                setSuccessMessage(AlertStrings.ADD_EVENT_SUCCESS);
              }
            })
            .catch(() => {
              setFailedMessage(AlertStrings.ADD_EVENT_ERROR);
            })
            .finally(() => {
              setShowToast(true);
              setIsLoading(false);
              toggle();
            });
        })
        .catch(() => {
          setFailedMessage(AlertStrings.UPLOAD_IMAGE_FAIL);
          setIsLoading(false);
          toggle();
        });
    } else {
      const imgLoc = GeneralConst.EVENT_DEFAULT_IMAGE;
      EventService.addEvent({ title, eventLink, date, imgLoc })
        .then((response) => {
          if (response.data.status === STATUS_CODES.ERROR) {
            setFailedMessage(response?.data?.statusMessage);
          } else {
            setSuccessMessage(AlertStrings.ADD_EVENT_SUCCESS);
          }
        })
        .catch(() => {
          setFailedMessage(AlertStrings.ADD_EVENT_ERROR);
        })
        .finally(() => {
          setShowToast(true);
          setIsLoading(false);
          toggle();
        });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      toggle={toggle}
      className="add-event-modal"
    >
      <ModalHeader toggle={toggle}>{eventStrings.ADD_EVENT}</ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            title: null,
            date: null,
            eventLink: null,
          }}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <Form className="padding-left-50 padding-right-50 margin-bottom-25 ">
            <FormControl className="margin-bottom-35" required>
              <FormLabel>{GeneralStrings.FORM.TITLE}</FormLabel>
              <Field
                name="title"
                className="form-control"
                validate={validateTextField}
              />
              <ErrorMessage
                component="span"
                className="form-error"
                name="title"
              />
            </FormControl>
            <FormControl className="margin-bottom-35" required>
              <FormLabel>{GeneralStrings.FORM.DATE}</FormLabel>
              <Field
                name="date"
                className="form-control"
                type="date"
                validate={validateTextField}
              />
              <ErrorMessage
                component="span"
                className="form-error"
                name="date"
              />
            </FormControl>
            <FormControl className="margin-bottom-35" required>
              <FormLabel>{GeneralStrings.FORM.LINK}</FormLabel>
              <Field
                name="eventLink"
                className="form-control"
                validate={validateTextField}
              />
              <ErrorMessage
                component="span"
                className="form-error"
                name="eventLink"
              />
            </FormControl>
            <FormControl className="margin-bottom-35">
              <FormLabel>{GeneralStrings.FORM.IMAGE}</FormLabel>
              <Field
                name="image"
                accept="image/*"
                type="file"
                onChange={handleFileChange}
                className="form-control"
              />
              <span className="image-notice">{eventStrings.IMAGE_NOTICE}</span>
              <ErrorMessage
                component="span"
                className="form-error"
                name="image"
              />
            </FormControl>
            <div className="flex flex-row">
              <Button
                type="submit"
                color="success"
                disabled={isLoading}
                className="text-lighter margin-left-auto"
              >
                {GeneralStrings.FORM.SUBMIT}
                {isLoading && (
                  <Spinner color="dark" size="sm" className="margin-left-10" />
                )}
              </Button>
            </div>
          </Form>
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default AddEventModal;
