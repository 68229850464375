import { Button, Link } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Recaptcha from "react-recaptcha";
import { Alert, Spinner } from "reactstrap";
import Footer from "../../components/Footer/Footer";
import HomepageNavbar from "../../components/HomepageNavbar/HomepageNavbar";
import GeneralConst from "../../consts/GeneralConst";
import Keys from "../../consts/Keys";
import AVAService from "../../services/AVAService";
import AlertStrings from "../../strings/AlertStrings.json";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./ContactPage.scss";

const TITLE = `Contact | ${GeneralConst.SITE_NAME}`;

const ContactPage = () => {
  const [recaptchaLoad, setRecaptchaLoad] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sentMessage, setSentMessage] = useState(false);
  const [failedMessage, setFailedMessage] = useState(false);
  const contactStrings = GeneralStrings.CONTACT;
  const STATUS_CODES = GeneralConst.STATUS_CODES;

  const recaptchaLoaded = () => {
    setRecaptchaLoad(true);
  };

  const verifiedRecaptcha = (response) => {
    if (response) {
      setIsVerified(true);
    }
  };

  const resetErrorMessages = () => {
    setSentMessage(false);
    setFailedMessage(false);
  };

  const handleSubmit = (formValues) => {
    setIsLoading(true);
    resetErrorMessages();

    if (recaptchaLoad && isVerified) {
      AVAService.submitContactForm(formValues)
        .then((response) => {
          if (response.data.status === STATUS_CODES.ERROR) {
            setFailedMessage(
              response?.data?.statusMessage || AlertStrings.GENERAL_ERROR
            );
          } else {
            setSentMessage(
              response?.data?.statusMessage || AlertStrings.GENERAL_SUCCESS
            );
          }
        })
        .catch((response) => {
          setFailedMessage(
            response?.data?.statusMessage || AlertStrings.GENERAL_ERROR
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    setIsLoading(false);
  };

  // TODO: Use validationHelper

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Email is Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid Email Address";
    }
    return error;
  }

  function validateName(value) {
    let error;
    if (value === null || value === "") {
      error = "Name is Required";
    }
    return error;
  }

  function validateSubject(value) {
    let error;
    if (value === null) {
      error = "Subject is Required";
    }
    return error;
  }

  function validateMessage(value) {
    let error;
    if (value === null) {
      error = "Message is Required";
    }
    return error;
  }

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <HomepageNavbar />
      <div className="contact" id="contact">
        <div className="container section-container">
          <div className="row">
            <div className="col-lg-4 col-xs-12">
              <h2 className="header cursive">{contactStrings.TITLE}</h2>
              <p className="description">{contactStrings.DESCRIPTION}</p>

              <div className="contact-points">
                <div className="contact-item flex flex-row">
                  <Link href="tel:3174268887" className="contact-link">
                    <i className="fa-solid fa-square-phone fa-2xl"></i>
                    <span className="margin-left-20">
                      {contactStrings.PHONE}
                    </span>
                  </Link>
                </div>
                <div className="contact-item flex flex-row">
                  <Link
                    href="mailto:generic.info@floraldesignlab.com"
                    className="contact-link"
                  >
                    <i className="fa-solid fa-square-envelope fa-2xl"></i>
                    <span className="margin-left-20">
                      {contactStrings.EMAIL}
                    </span>
                  </Link>
                </div>
                <hr />
                <div className="contact-item flex flex-row margin-top-25">
                  <Link
                    href="https://www.instagram.com/floral.design.lab?igsh=dnlmdHNtajl3aDlm"
                    target="_blank"
                    className="social-link"
                  >
                    <i className="fa-brands fa-instagram social-icon fa-2xl"></i>
                  </Link>
                  <Link
                    href="https://www.facebook.com/profile.php/?id=61552899370557"
                    target="_blank"
                    className="social-link"
                  >
                    <i className="fa-brands fa-facebook-square social-icon fa-2xl"></i>
                  </Link>
                  <Link
                    href="https://www.linkedin.com/company/floraldesignlab/about/"
                    target="_blank"
                    className="social-link"
                  >
                    <i className="fa-brands fa-linkedin social-icon fa-2xl"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-8 col-xs-12 padding-top-20">
              <div className="contact-form col-md-12">
                {failedMessage && (
                  <Alert color="danger">{AlertStrings.CONTACT_ERROR}</Alert>
                )}

                {sentMessage && <Alert>{AlertStrings.CONTACT_SUCCESS}</Alert>}

                <Formik
                  initialValues={{
                    email: "",
                    name: "",
                    message: "",
                    subject: "",
                  }}
                  onSubmit={(values) => {
                    handleSubmit(values);
                  }}
                >
                  <Form>
                    <div className="form-group">
                      <Field
                        name="name"
                        className="form-control input-color"
                        placeholder={GeneralStrings.FORM.FORM_LABELS.YOUR_NAME}
                        validate={validateName}
                      />
                      <ErrorMessage
                        component="span"
                        className="form-error"
                        name="name"
                      />
                    </div>

                    <div className="form-group">
                      <Field
                        name="email"
                        className="form-control input-color"
                        placeholder={GeneralStrings.FORM.FORM_LABELS.YOUR_EMAIL}
                        validate={validateEmail}
                      />
                      <ErrorMessage
                        component="span"
                        className="form-error"
                        name="email"
                      />
                    </div>

                    <div className="form-group">
                      <Field
                        name="subject"
                        className="form-control input-color"
                        placeholder={GeneralStrings.FORM.FORM_LABELS.SUBJECT}
                        validate={validateSubject}
                      />
                      <ErrorMessage
                        component="span"
                        className="form-error"
                        name="subject"
                      />
                    </div>

                    <div className="form-group">
                      <Field
                        as="textarea"
                        name="message"
                        className="form-control input-color"
                        placeholder={GeneralStrings.FORM.FORM_LABELS.MESSAGE}
                        validate={validateMessage}
                        rows={5}
                        type="textarea"
                      />
                      <ErrorMessage
                        component="span"
                        className="form-error"
                        name="message"
                      />
                    </div>

                    <div className="form-group">
                      <Recaptcha
                        sitekey={Keys.CAPTCHA_SITE_KEY}
                        render="explicit"
                        onloadCallback={recaptchaLoaded}
                        verifyCallback={verifiedRecaptcha}
                      />
                    </div>

                    <div className="form-group margin-top-20">
                      <Button
                        fullWidth
                        className="btn btn-floral-primary btn-contact-submit"
                        type="submit"
                      >
                        <span className="submit-text">
                          {GeneralStrings.FORM.SUBMIT}
                        </span>
                        {isLoading && (
                          // TODO: Remove spans from spinners
                          <Spinner color="dark" size="sm">
                            <span className="sr-only">
                              {GeneralStrings.FORM.FORM_LABELS.LOADING}
                            </span>
                          </Spinner>
                        )}
                      </Button>
                    </div>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactPage;
