export function validateConfirmPassword(password, confirmPassword) {
  let error;
  if (confirmPassword === null) {
    error = "Password is Required";
  }

  if (confirmPassword !== password) {
    error = "Passwords Do Not Match";
  }

  return error;
}

export function validateEmail(value) {
  let error;
  if (!value) {
    error = "Email is Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
    error = "Invalid Email Address";
  }
  return error;
}

export function validateMessage(value) {
  let error;
  if (value === null) {
    error = "Message is Required";
  }
  return error;
}

export function validateName(value) {
  let error;
  if (value === null || value === "") {
    error = "Name is Required";
  }
  return error;
}

export function validatePassword(value) {
  let error;
  if (value === null) {
    error = "Password is Required";
  }
  return error;
}

export function validateSubject(value) {
  let error;
  if (value === null) {
    error = "Subject is Required";
  }
  return error;
}

export function validateTextField(value) {
  let error;
  if (value === null) {
    error = "Required";
  }
  return error;
}
