import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { Alert, Grid, Paper, Snackbar } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { Spinner } from "reactstrap";
import DashboardSubPageToolbar from "../../../components/DashboardSubPageToolbar/DashboardSubPageToolbar";
import AVAService from "../../../services/AVAService";
import AlertStrings from "../../../strings/AlertStrings.json";
import GeneralStrings from "../../../strings/GeneralStrings.json";
import { validateTextField } from "../../../utilities/ValidationHelper";
import DashboardLayout from "../DashboardLayout/DashboardLayout";
import "./HelpPage.scss";

const HelpPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [failedMessage, setFailedMessage] = useState(null);
  const helpPageStrings = GeneralStrings.DASHBOARD.HELP_PAGE;

  const clearFormFields = () => {};

  const handleSubmit = (formValues) => {
    setIsLoading(true);
    AVAService.submitHelpRequest(formValues)
      .then(() => {
        setSuccessMessage(AlertStrings.HELP_MESSAGE_SUCCESS);
      })
      .catch(() => {
        setFailedMessage(AlertStrings.HELP_MESSAGE_ERROR);
      })
      .finally(() => {
        clearFormFields();
        setShowToast(true);
        setIsLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <Grid container spacing={3} className="help-page">
        <DashboardSubPageToolbar title={helpPageStrings.HELP} />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={showToast}
          autoHideDuration={5000}
          onClose={() => setShowToast(false)}
        >
          <div className="dashboard-snackbar">
            {successMessage && (
              <Alert severity="success">{successMessage}</Alert>
            )}
            {failedMessage && <Alert severity="error">{failedMessage}</Alert>}
          </div>
        </Snackbar>
        <Grid item xs={12}>
          <Paper elevation={0} className="help-page-container">
            <Formik
              initialValues={{
                firstName: null,
                lastName: null,
                email: null,
                message: null,
              }}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              <Form className="padding-left-50 padding-right-50 padding-top-25">
                <h5 className="description">{helpPageStrings.DESCRIPTION}</h5>
                <FormControl className="margin-bottom-35" required>
                  <FormLabel>{GeneralStrings.FORM.FIRST_NAME}</FormLabel>
                  <Field
                    name="firstName"
                    className="form-control"
                    validate={validateTextField}
                  />
                  <ErrorMessage
                    component="span"
                    className="form-error"
                    name="firstName"
                  />
                </FormControl>
                <FormControl className="margin-bottom-35" required>
                  <FormLabel>{GeneralStrings.FORM.LAST_NAME}</FormLabel>
                  <Field
                    name="lastName"
                    className="form-control"
                    validate={validateTextField}
                  />
                  <ErrorMessage
                    component="span"
                    className="form-error"
                    name="lastName"
                  />
                </FormControl>
                <FormControl className="margin-bottom-35" required>
                  <FormLabel>{GeneralStrings.FORM.EMAIL}</FormLabel>
                  <Field
                    name="email"
                    className="form-control"
                    validate={validateTextField}
                  />
                  <ErrorMessage
                    component="span"
                    className="form-error"
                    name="email"
                  />
                </FormControl>
                <FormControl className="margin-bottom-35" required>
                  <FormLabel>{GeneralStrings.FORM.MESSAGE}</FormLabel>
                  <Field
                    name="message"
                    component="textarea"
                    className="form-control"
                    validate={validateTextField}
                  />
                  <ErrorMessage
                    component="span"
                    className="form-error"
                    name="message"
                  />
                </FormControl>
                <div className="flex flex-row">
                  <Button
                    type="submit"
                    color="success"
                    disabled={isLoading}
                    className="text-lighter margin-left-auto margin-bottom-25"
                  >
                    {GeneralStrings.FORM.SUBMIT}
                    {isLoading && (
                      <Spinner
                        color="dark"
                        size="sm"
                        className="margin-left-10"
                      />
                    )}
                  </Button>
                </div>
              </Form>
            </Formik>
          </Paper>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default HelpPage;
