import { Link, SwipeableDrawer } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import RoutesConst from "../../consts/RoutesConst";
import GeneralStrings from "../../strings/GeneralStrings";
import "./MobileNavDrawer.scss";

const MobileNavDrawer = ({ isDrawerOpen, setIsDrawerOpen }) => {
  const navBarStrings = GeneralStrings.NAVBAR;

  return (
    <SwipeableDrawer
      anchor="left"
      className="mobile-nav-drawer"
      open={isDrawerOpen}
      onOpen={() => {}}
      onClose={() => setIsDrawerOpen(false)}
    >
      <div className="mobile-nav-link-container flex-col">
        <RouterLink
          to={RoutesConst.DEFAULT}
          className="nav-link"
          onClick={() => setIsDrawerOpen(false)}
        >
          {navBarStrings.HOME}
        </RouterLink>
        <RouterLink
          to={RoutesConst.EVENTS}
          className="nav-link"
          onClick={() => setIsDrawerOpen(false)}
        >
          {navBarStrings.EVENTS}
        </RouterLink>
        <RouterLink
          to={RoutesConst.PHOTO_GALLERY}
          className="nav-link"
          onClick={() => setIsDrawerOpen(false)}
        >
          {navBarStrings.PHOTO_GALLERY}
        </RouterLink>
        <RouterLink
          to={RoutesConst.CONTACT}
          className="nav-link"
          onClick={() => setIsDrawerOpen(false)}
        >
          {navBarStrings.CONTACT}
        </RouterLink>
        <hr className="social-divider" />
        <div className="mobile-nav-social-links-container flex">
          <Link
            href="https://www.instagram.com/floral.design.lab?igsh=dnlmdHNtajl3aDlm"
            target="_blank"
            className="nav-social-link"
          >
            <i className="fa-brands fa-instagram social-icon fa-lg"></i>
          </Link>
          <Link
            href="https://www.facebook.com/profile.php/?id=61552899370557"
            target="_blank"
            className="nav-social-link"
          >
            <i className="fa-brands fa-facebook-square social-icon fa-lg"></i>
          </Link>
          <Link
            href="https://www.linkedin.com/company/floraldesignlab/about/"
            target="_blank"
            className="nav-social-link"
          >
            <i className="fa-brands fa-linkedin social-icon fa-lg"></i>
          </Link>
        </div>
      </div>
    </SwipeableDrawer>
  );
};

export default MobileNavDrawer;
