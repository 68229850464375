import { Link } from "@mui/material";
import React from "react";
import GeneralStrings from "../../strings/GeneralStrings.json";
import { getCurrentYear } from "../../utilities/DateHelper.js";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer className="bg-black text-center site-footer">
      <div className="footer-social-links">
        <Link
          href="https://www.instagram.com/floral.design.lab?igsh=dnlmdHNtajl3aDlm"
          target="_blank"
          className="social-link"
        >
          <i className="fa-brands fa-instagram social-icon fa-2xl"></i>
        </Link>
        <Link
          href="https://www.facebook.com/profile.php/?id=61552899370557"
          target="_blank"
          className="social-link"
        >
          <i className="fa-brands fa-facebook-square social-icon fa-2xl"></i>
        </Link>
        <Link
          href="https://www.linkedin.com/company/floraldesignlab/about/"
          target="_blank"
          className="social-link"
        >
          <i className="fa-brands fa-linkedin social-icon fa-2xl"></i>
        </Link>
      </div>
      <hr />
      <div className="copyright">
        <span> © {getCurrentYear()} </span>
        <span
          dangerouslySetInnerHTML={{ __html: GeneralStrings.FOOTER_HTML }}
        />
      </div>
    </footer>
  );
};

export default Footer;
