import Button from "@mui/joy/Button";
import { Form, Formik } from "formik";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import GeneralStrings from "../../strings/GeneralStrings.json";
import "./ConfirmDeleteModal.scss";

const ConfirmDeleteModal = ({
  deleteMessage,
  handleDelete,
  isOpen,
  deleteValue,
  secondaryDeleteValue = null,
  toggle,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      size="lg"
      toggle={toggle}
      className="confirm-delete-modal"
    >
      <ModalHeader toggle={toggle} className="margin-bottom-20">
        {GeneralStrings.FORM.CONFIRM_DELETE}
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            deleteValue: deleteValue,
            secondaryDeleteValue: secondaryDeleteValue,
          }}
          onSubmit={(values) => {
            handleDelete(values);
          }}
        >
          <Form className="padding-left-50 padding-right-50 margin-bottom-25">
            <h4 className="margin-bottom-40">
              {deleteMessage || GeneralStrings.FORM.CONFIRM_DELETE_DESCRIPTION}
            </h4>
            <div className="flex flex-row">
              <Button
                type="submit"
                color="primary"
                className="text-lighter margin-left-auto"
                onClick={toggle}
              >
                {GeneralStrings.FORM.CANCEL}
              </Button>
              <Button
                type="submit"
                color="danger"
                className="text-lighter margin-left-20"
              >
                {GeneralStrings.FORM.DELETE}
              </Button>
            </div>
          </Form>
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default ConfirmDeleteModal;
