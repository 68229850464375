import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import RoutesConst from "./consts/RoutesConst";
import ContactPage from "./pages/ContactPage/ContactPage";
import AccountSettings from "./pages/Dashboard/AccountSettings/AccountSettings";
import Dashboard from "./pages/Dashboard/Dashboard";
import Events from "./pages/Dashboard/Events/Events";
import HelpPage from "./pages/Dashboard/Help/HelpPage";
import PhotoGallery from "./pages/Dashboard/PhotoGallery/PhotoGallery";
import Users from "./pages/Dashboard/Users/Users";
import EventsPage from "./pages/EventsPage/EventsPage";
import Homepage from "./pages/Homepage";
import LoginPage from "./pages/LoginPage/LoginPage";
import PhotoGalleryPage from "./pages/PhotoGalleryPage/PhotoGalleryPage";

export default class App extends Component {
  static displayName = App.name;

  /* TODO: Nest dashboard subpages into dashboard */

  render() {
    return (
      <Routes>
        <Route index path={RoutesConst.DEFAULT} element={<Homepage />} />
        <Route path={RoutesConst.EVENTS} element={<EventsPage />} />
        <Route
          path={RoutesConst.PHOTO_GALLERY}
          element={<PhotoGalleryPage />}
        />
        <Route path={RoutesConst.CONTACT} element={<ContactPage />} />
        <Route path={RoutesConst.DASHBOARD.DEFAULT} element={<Dashboard />} />
        <Route path={RoutesConst.DASHBOARD.USERS} element={<Users />} />
        <Route
          path={RoutesConst.DASHBOARD.ACCOUNT_SETTINGS}
          element={<AccountSettings />}
        />
        <Route path={RoutesConst.DASHBOARD.HELP} element={<HelpPage />} />
        <Route
          path={RoutesConst.DASHBOARD.PHOTO_GALLERY}
          element={<PhotoGallery />}
        />
        <Route path={RoutesConst.DASHBOARD.EVENTS} element={<Events />} />
        <Route path={RoutesConst.LOGIN} element={<LoginPage />} />
      </Routes>
    );
  }
}
